<template>
    <div class="loginBox flex_c_c">
        <div class="flex_c_c w100 h100" v-if="!token">
            <div class="leftBox">
                <img src="../../../../assets/img/login_cultural.png" class="login_cultural">
                <img src="../../../../assets/img/logo_white.png" class="logo">
            </div>
            <div class="rightBox">
                <div class="welcomeTxt">你好，欢迎登录</div>
                <div class="flex_l_c item">
                    <img src="../../../../assets/img/login_account.png" class="accountIcon">
                    <el-input v-model="inputForm.loginName" placeholder="请输入账号" size="mini"></el-input>
                </div>
                <div class="flex_l_c item">
                    <img src="../../../../assets/img/login_pwd.png" class="pwdIcon">
                    <el-input v-model="inputForm.password" placeholder="请输入密码" size="mini" show-password
                              auto-complete="new-password" @keyup.enter.native="login()"></el-input>
                </div>
                <el-checkbox v-model="rememberPwd">记住密码</el-checkbox>
                <el-button type="primary" :loading="loading" class="loginBtn" @click="login">登录</el-button>
<!--                <div class="loginBtn pointer" @click="login">登录</div>-->
            </div>
        </div>
        <div v-else>获取用户权限中，请稍后~</div>
    </div>
</template>

<script>
    import CryptoJS from "crypto-js";
    export default {
        data() {
            return {
                token: this.QueryString('token') || '',
                id: this.QueryString('id') || '',
                loading: false,
                inputForm: {
                    loginName: '',
                    password: '',
                },
                loginForm: {
                    operModular: '1',
                    operType: '0',
                    operTerm: '登录',
                    operSystem: '0'
                },
                rememberPwd: false,
            }
        },
        mounted() {
            sessionStorage.setItem('indexType', '1')
            document.title = '线上藏品系统'
            if(this.token) {
                sessionStorage.setItem('token', this.token)
                document.cookie = `satoken=${this.token}`
                this.getUserInfo();
            }
            this.getCookie();
        },
        methods: {
            // 提交表单
            login() {
                if (this.inputForm.loginName == '' || this.inputForm.password == '') {
                    this.$message.error('请输入账号或密码');
                    return false
                }
                if (this.rememberPwd) {
                    this.setCookie(this.inputForm.loginName, this.inputForm.password, 7);
                } else {
                    //清空Cookie
                    this.clearCookie();
                }
                this.loading = true
                this.$axios(this.api.sys.login, this.inputForm, 'post').then(data => {
                    if (data.status) {
                        sessionStorage.setItem('token', data.data.token)
                        document.cookie = `satoken=${data.data.token}`
                        this.$store.commit('user/updateUser', data.data)
                        // this.socketApi.initWebSocket()
                        sessionStorage.setItem('indexType', '1')
                        this.getMenus(1);
                        this.$axios(this.api.sys.logSave, this.loginForm, 'post').then(data => {
                            if (data.status) {
                                console.log(data)
                            }
                        })
                    } else {
                        this.$message({
                            message: data.msg,
                        });
                        this.loading = false
                    }
                })
            },
            // 获取用户权限
            getMenus(type) {
                let parma = {
                    type:type
                }
                this.$axios(this.api.sys.getMenus, parma, 'get').then(data => {
                    if (data.status) {
                        sessionStorage.setItem('allMenuList', JSON.stringify(data.data.menuList || '[]'))
                        sessionStorage.setItem('permissions', JSON.stringify(data.data.permissions || '[]'))
                        // this.$router.push({name: 'home'})
                        this.$router.push({path: '/database/published'})
                        sessionStorage.removeItem('loginState')
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            },
            // 获取用户信息
            getUserInfo() {
                this.$axios(this.api.sys.sysUserQueryById, {id: this.id}, 'get').then(data => {
                    if(data.status && data.data){
                        this.$store.commit('user/updateUser', data.data)
                        this.getMenus(1);
                    }
                })
            },
            //储存cookie
            setCookie(name, pwd, exdays) {
                var exdate = new Date();
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
                // Encrypt，加密账号密码
                var cipherName = CryptoJS.AES.encrypt(name + "", "secretkey123").toString();
                var cipherPwd = CryptoJS.AES.encrypt(pwd + "", "secretkey123").toString();
                document.cookie = "dyygUserName=" + cipherName + ";path=/;expires=" + exdate.toGMTString()
                document.cookie = "dyygUserPwd=" + cipherPwd + ";path=/;expires=" + exdate.toGMTString()
            },
            //获取cookie
            getCookie: function () {
                if (document.cookie.length > 0) {
                    var arr = document.cookie.split('; ');
                    for (var i = 0; i < arr.length; i++) {
                        var arr2 = arr[i].split('=');
                        if (arr2[0] == 'dyygUserName') {
                            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
                            this.inputForm.loginName = bytes.toString(CryptoJS.enc.Utf8);
                        } else if (arr2[0] == 'dyygUserPwd') {
                            var bytes = CryptoJS.AES.decrypt(arr2[1], "secretkey123");
                            this.inputForm.password = bytes.toString(CryptoJS.enc.Utf8);
                            if (arr2[1] != "") {
                                this.rememberPwd = true;
                            }
                        }

                    }
                }
            },
            //删除cookie
            clearCookie: function () {
                this.setCookie("", "", -1);
            },
        }
    }
</script>
